<template>
  <article
    v-if="content"
    class="the-footer-link-collection content-wrap text-center"
  >
    <span class="text-h5">
      {{ content.headline }}
    </span>

    <p v-if="content.caption" class="mt-xs text-caption">
      {{ content.caption }}
    </p>

    <ul
      v-if="links.length"
      class="list flex flex-wrap justify-center mt-sm"
      :class="type === 'app' ? 'gap-md' : 'gap-2xl'"
    >
      <li v-for="{ title, to, ...imgProps } in links" :key="title">
        <HyperLink v-bind="{ title, to }">
          <LcImage v-bind="imgProps" />
        </HyperLink>
      </li>
    </ul>
  </article>
</template>

<script lang="ts" setup>
type LinkCollectionLink = {
  alt: string
  height: number
  src: string
  title: string
  to: string
  width: number
}

type TheFooterLinkCollectionProps = {
  gap: '2xl' | 'md'
  type: 'app' | 'social'
}

const props = defineProps<TheFooterLinkCollectionProps>()

const { getFooterImage } = useAsset()
const { t } = useI18n()

const footer = useFooter()

const content = computed(() => {
  if (!footer.value?.items[0]?.socialMedia) return null
  const contentType = props.type === 'app' ? 'appDownload' : 'socialMedia'
  const content = footer.value?.items[0][contentType]

  // TODO Remove fallbacks when contentful schema finally gets it’s shit together
  return {
    caption: content?.caption ?? null,
    headline: content?.headline ?? '',
    linksCollection: content?.linksCollection ?? null,
  }
})

const links = computed<LinkCollectionLink[]>(() => {
  if (!content.value) return []
  const { linksCollection } = content.value

  // TODO Remove fallbacks when contentful schema finally gets it’s shit together
  return (
    linksCollection?.items.filter(isTruthy).map(({ link, type }) => {
      const icon = getFooterImage(`${props.type}-${normalizeKey(type ?? '')}`)

      return {
        alt: type ?? '',
        height: icon?.height ?? 0,
        src: icon?.src ?? '',
        title: t(`thefooter.${props.type}.link.title`, { platform: type }),
        to: link ?? '',
        width: icon?.width ?? 0,
      }
    }) ?? []
  )
})
</script>

<style lang="scss" scoped>
.the-footer-link-collection {
  .list {
    a {
      position: relative;
      display: block;

      @include focus-state {
        &::before {
          inset: 0;
          border-radius: $border-radius-sm;
          box-shadow:
            0 0 0 0.125rem color('white'),
            0 0 0 0.3125rem color('info700');
        }
      }
    }
  }
}
</style>
