<template>
  <footer class="the-footer mt-2xl">
    <section class="banner flex flex-col gap-3xl py-xl">
      <TheFooterRegisteredCourier v-if="variant === 'full'" />
      <TheFooterLinkCollection v-if="!isNativeApp" gap="md" type="app" />
      <TheFooterLinkCollection gap="2xl" type="social" />
    </section>

    <section class="foot-note py-xl">
      <div class="content-wrap flex flex-col gap-lg px-xs">
        <TheFooterNavigation />

        <article v-if="content" class="text-center text-caption">
          <strong v-if="content.copyright">
            {{ content.copyright }}
          </strong>
          <p v-if="content.address">
            {{ content.address }}
          </p>
        </article>

        <TheFooterResponsiblePlay />
      </div>
    </section>
  </footer>
</template>

<script lang="ts" setup>
import type { TheFooterProps } from '~/features/TheFooter/TheFooter.vue'

import TheFooterResponsiblePlay from '~/features/TheFooter/TheFooterResponsiblePlay.vue'

withDefaults(defineProps<TheFooterProps>(), {
  variant: 'condensed',
})

const footer = useFooter()
const { isNativeApp } = useCustomContext()

const content = computed(() => {
  if (!footer.value?.items[0]) return null
  const { addressLine, copyrightLine } = footer.value.items[0]

  return {
    address: addressLine ?? null,
    copyright: copyrightLine ?? null,
  }
})
</script>

<style lang="scss" scoped>
.the-footer {
  > section:first-child {
    position: relative;

    > * + * {
      &::before {
        content: '';
        position: absolute;
        right: spacing('xs');
        left: spacing('xs');
        display: block;
        margin-top: -1 * spacing('lg');
        border-bottom: 1px solid color('secondary400');
      }
    }
  }

  .foot-note {
    background-color: token('color.background.main.elements.inactive');
    color: token('color.text.primary');
  }

  .banner {
    background-color: token('color.background.main.secondary');
  }
}
</style>
